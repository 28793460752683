<template>
  <div class="appel-paiement">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2" v-if="checkPermission('GAPCFDF')">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Date début</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            required
            v-model="date_debut"
            :clear-button="true"
            :disabled-date="disabledStartDate"
            @change="handleFilterDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
          ></date-picker>
        </div>
        <div class="box-label-champ mr-2" v-if="checkPermission('GAPCFDF')">
          <div class="label-box-style w-63-px">
            <span class="title-tabel">Date fin</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            v-model="date_fin"
            :clear-button="true"
            :disabled-date="disabledEndDate"
            @change="handleFilterDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
          ></date-picker>
        </div>
        <div
          v-if="
            getLoadingAppelPaiementCommerciaux || getLoadingSettingFilialeTh
          "
          class="chargement"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="error-message ml-2">
          <div v-if="getErrorAppelPaiement" class="error">
            <ul v-if="Array.isArray(getErrorAppelPaiement)" class="mb-0">
              <li v-for="(e, index) in getErrorAppelPaiement" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorAppelPaiement }}</div>
          </div>
        </div>
        <div class="box-end-position">
          <search
            v-if="checkPermission('GAPTHREC')"
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            :dynamic="false"
            placeHolder="Num dossier"
          />
          <mail-commercial
            @eventMailSendRefreshTable="handleFilter"
            :dataToUse="computedCheckDossier"
            v-if="
              checkPermission('GAPTHARF') &&
                computedCheckDossier &&
                computedCheckDossier.length
            "
          />
          <!-- <regenerer-facturecommerciaux
            @reloadData="handleFilter"
            :dataToUse="computedCheckFactures"
            v-if="
              checkPermission('GAPTHARF') &&
                computedCheckFactures &&
                computedCheckFactures.length
            "
          /> -->
          <!-- checkPermission('GAPTHAAAP') && -->
          <lock-ap
            :dataToUse="computedCheckRowsLock"
            v-if="
              computedCheckRowsLock &&
                computedCheckRowsLock.length &&
                computedCheckRowsLock.length == computedCheckRows.length &&
                isSuperAdminGetter
            "
          />
          <!-- <regenererFraisCommerciaux
            :dataToUse="computedCheckRows"
            :generateFunction="paymentCallCommercial"
            v-if="
              checkPermission('GAPTHAAP') &&
                computedCheckRows &&
                computedCheckRows.length
            "
            @reloadData="reloadData"
          /> -->
          <update-multiple-column-appel-paiement
            v-if="
              checkPermission('GAPTHMMCSRCRAC') &&
                computedCheckRowsUpdate &&
                computedCheckRowsUpdate.length
            "
            :dataToUse="computedCheckRowsUpdate"
            :optionsSelectColumnSousRegie="computedGetSettingAllFilialesTh"
            :optionsSelectColumnCommercial="computedGetSettingAllParticulierTh"
            :optionsSelectStatut="computedListStatut"
            :generateFunction="updateColumnsCommercialMultiple"
            @reloadData="reloadData"
            :updateAll="computedCheckAllRowsUpdate"
          />

          <b-button
            class="button-export-style add-facture-style-libre ml-2"
            title="Vider l'ordre"
            @click="refrech()"
            v-if="orderBy != 'id' && checkPermission('GAPCVOC')"
          >
            <font-awesome-icon icon="battery-empty"
          /></b-button>
          <b-button
            size="sm"
            class="button-export-style ml-2 mr-2"
            title="Filter"
            id="popover-target-1"
          >
            <font-awesome-icon icon="filter" />
          </b-button>
          <b-popover
            triggers="hover"
            target="popover-target-1"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <filter-component
              label="Installateur"
              classLabel="w-126-px"
              v-if="checkPermission('GAPCFI')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="installateur"
                  :multiple="true"
                  @input="handleFilter"
                  label="text"
                  :options="computedFilterInstallateur"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  :loading="loadingFiltreCommerciaux"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Installateur</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Installateurs</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Lot"
              classLabel="w-126-px"
              v-if="checkPermission('GAPCFL')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="lots"
                  :multiple="true"
                  @input="handleFilter"
                  label="text"
                  :options="computedFilterLots"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="loadingFiltreCommerciaux"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Lot</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Lots</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              classLabel="w-126-px"
              v-if="checkPermission('GAPCFAAP')"
              label="Appel paiement"
            >
              <template v-slot:body>
                <b-form-select
                  class="
                    b-form-select-new-style b-form-select-raduis-border
                    mt-2
                    mb-2
                  "
                  v-model="payment_call"
                  @input="handleFilter"
                  :options="computedFilterPayment"
                  text-field="text"
                  value-field="value"
                ></b-form-select
              ></template>
            </filter-component>
            <filter-component
              label="Régie"
              classLabel="w-126-px"
              v-if="checkPermission('GAPCFRE')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="regie"
                  :multiple="true"
                  @input="handleFilter"
                  :options="computedGetSettingRegiesTh"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  :loading="getLoaderListRegieForCommercialpage"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Sous régie</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Sous régies</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Commercial"
              classLabel="w-126-px"
              v-if="checkPermission('GAPCFCO')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="agent_commercial"
                  :multiple="true"
                  @input="handleFilter"
                  label="full_name"
                  :options="computedGetSettingFilialesTh"
                  track-by="id"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  :loading="loadingFiltreCommerciaux"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Commercial</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Commercials</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              classLabel="w-126-px"
              v-if="checkPermission('GAPRACAAP')"
              label="Reste à charge"
            >
              <template v-slot:body>
                <b-form-select
                  class="
                    b-form-select-new-style b-form-select-raduis-border
                    mt-2
                    mb-2
                  "
                  v-model="commercial_reste_a_charge"
                  @input="handleFilter"
                  :options="computedFilterResteChage"
                  text-field="text"
                  value-field="value"
                ></b-form-select
              ></template>
            </filter-component>
            <filter-component
              label="Statut Appel à paiement"
              classLabel="w-126-px"
              v-if="checkPermission('GAPCFCO')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="statut_appel_commercial"
                  :multiple="true"
                  @input="handleFilter"
                  label="full_name"
                  :options="listStatut"
                  track-by="id"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Statut</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Statuts</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
          </b-popover>
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="
          date_debut !== null ||
            date_fin !== null ||
            (searchValue != null && searchValue != '') ||
            payment_call != null ||
            commercial_reste_a_charge !== 'tous'
        "
        class="block-filter-style"
      >
        <span class="phrase-style">Les filtres choisie sont: </span>
        <span class="item-filter-style" v-if="date_debut !== null"
          >Date début: {{ date_debut }}</span
        >
        <span class="item-filter-style" v-if="date_fin !== null"
          >Date fin: {{ date_fin }}</span
        >
        <span
          v-if="searchValue != null && searchValue != ''"
          class="item-filter-style"
          >Recherche : {{ searchValue }}</span
        >
        <span v-if="payment_call != null" class="item-filter-style"
          >Appel à paiement : {{ payment_call }}</span
        >
        <span
          v-if="commercial_reste_a_charge !== 'tous'"
          class="item-filter-style"
          >Reste à charge :
          {{ commercial_reste_a_charge | filterCommercialResteACharge }}</span
        >
      </div>
    </div>
    <div class="chip-filters-simulation">
      <div
        v-if="statut_appel_commercial && statut_appel_commercial.length"
        class="block-filter"
      >
        <span class="title-block-chip">Statut appel à paiement</span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="statut_appel_commercial"
          @input="handleFilter"
        >
          <vs-chip
            :key="vd.full_name + 'statut_appel_commercial'"
            @click="remove(vd, 'statut_appel_commercial', 'handleFilter')"
            v-for="vd in statut_appel_commercial"
            closable
          >
            {{ vd.full_name }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="lots && lots.length" class="block-filter">
        <span class="title-block-chip">Lot(s) :</span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="lots"
          @input="handleFilter"
        >
          <vs-chip
            :key="vd.text + 'lots'"
            @click="remove(vd, 'lots', 'handleFilter')"
            v-for="vd in lots"
            closable
          >
            {{ vd.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="installateur && installateur.length" class="block-filter">
        <span class="title-block-chip">Installateurs : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="installateur"
          @input="handleFilter"
        >
          <vs-chip
            :key="p.text + 'acheteur'"
            @click="remove(p, 'installateur', 'handleFilter')"
            v-for="p in installateur"
            closable
          >
            {{ p.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="regie && regie.length" class="block-filter">
        <span class="title-block-chip">Régie(s) : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="regie"
          @input="handleFilter"
        >
          <vs-chip
            :key="vd.text + 'regie'"
            @click="remove(vd, 'regie', 'handleFilter')"
            v-for="vd in regie"
            closable
          >
            {{ vd }}
          </vs-chip>
        </vs-chips>
      </div>
      <div
        v-if="agent_commercial && agent_commercial.length"
        class="block-filter"
      >
        <span class="title-block-chip fix-width-agent"
          >Agent commercial :
        </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="agent_commercial"
          @input="handleFilter"
        >
          <vs-chip
            :key="p.text + 'acheteur'"
            @click="remove(p, 'agent_commercial', 'handleFilter')"
            v-for="p in agent_commercial"
            closable
          >
            {{ p.full_name }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>
    <!--End block filtre -->
    <div class="body-box-rapport" :style="sizeBlockTable">
      <div class="tabs-menu-style">
        <div>
          <div class="dot-vert-big" title="Statut Appel à paiement Payé"></div>
          <hr class="ligne" />
          <div
            class="dot-red-big"
            title="Statut Appel à paiement A payer"
          ></div>
          <hr class="ligne" />
        </div>
      </div>
      <div class="table-rapport-style w-100-p">
        <b-table
          :style="sizeTable"
          show-empty
          id="my-table"
          class="custom-table-style tableAppelPaiement table-header"
          :items="getAppelPaiementCommerciaux"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div v-if="data.field.key === 'check_all'">
              <b-form-checkbox
                class="check-th-details"
                :value="true"
                :unchecked-value="false"
                @change="checkAllFunction"
                v-model="checkAll"
              >
              </b-form-checkbox>
            </div>
            <div
              v-else
              :class="{
                'd-flex justify-content-center align-items-center':
                  data.field.isSortable === true,
                'd-flex justify-content-center':
                  data.field.isSortable === false,
                'underline-selected-colomn': data.field.key === orderBy
              }"
            >
              {{ data.field.label }}

              <font-awesome-icon
                v-if="data.field.isSortable === true"
                @click="listOrder(data.field.key, 'ASC')"
                icon="arrow-up"
                :class="
                  data.field.key === orderBy && order === 'ASC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
              {{ ' ' }}
              <font-awesome-icon
                @click="listOrder(data.field.key, 'DESC')"
                v-if="data.field.isSortable === true && checkPermission('FLOC')"
                icon="arrow-down"
                :class="
                  data.field.key === orderBy && order === 'DESC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkAllVerif(data.item)"
              name="flavour-1"
              class="check-th-details"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(numero_dossier)="data">
            {{ data.item.numero_dossier }}
          </template>
          <template v-slot:cell(installeur)="data">
            {{ data.item.installeur }}
          </template>
          <template v-slot:cell(nom)="data">
            {{ data.item.nom1 }}
          </template>
          <template v-slot:cell(prenom)="data">
            {{ data.item.prenom1 }}
          </template>
          <template v-slot:cell(regie)="data">
            {{ data.item.regie }}
          </template>
          <template v-slot:cell(date_depot_dossier)="data">
            {{ data.item.date_depot_dossier }}
          </template>
          <template v-slot:cell(statut)="data">
            {{ data.item.statut }}
          </template>
          <template v-slot:cell(sous_regie)="data">
            <EditableInput
              :editable="
                checkPermission('GAPTHMCSR') &&
                  data.item.payment_call != 'oui' &&
                  data.item.has_proforma == false
              "
              champName="sous_regie"
              editableType="selectInput"
              :optionsSelect="computedGetSettingAllFilialesTh"
              :item="data.item"
              :value="data.item.sous_regie"
              :updateFunction="updateColumnsCommercial"
              label="full_name"
              :positionSelect="true"
              :showIconEdit="true"
            />
          </template>
          <template v-slot:cell(commercial_reste_a_charge)="data">
            <EditableInput
              :editable="
                checkPermission('GAPTHMCCRAC') &&
                  data.item.payment_call != 'oui' &&
                  data.item.has_proforma == false
              "
              champName="commercial_reste_a_charge"
              :item="data.item"
              :value="data.item.commercial_reste_a_charge"
              type="number"
              editableType="input"
              :updateFunction="updateColumnsCommercial"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(agent_commercial_terrain)="data">
            <EditableInput
              :editable="
                checkPermission('GAPTHMCACT') &&
                  data.item.payment_call != 'oui' &&
                  data.item.has_proforma == false
              "
              champName="agent_commercial_terrain"
              editableType="selectInput"
              :optionsSelect="computedGetSettingFilialesTh"
              :item="data.item"
              :value="data.item.agent_commercial_terrain"
              :updateFunction="updateColumnsCommercial"
              label="full_name"
              :positionSelect="true"
              :showIconEdit="true"
            />
          </template>
          <template v-slot:cell(piece_jointe)="data">
            <font-awesome-icon
              v-show="
                data.item.appel_facturation &&
                  data.item.appel_facturation.length
              "
              :id="`popover-1-${data.item.id}`"
              icon="info"
              class="
                          info-icon-commercial-lot
                          content-tooltip-style
                          w-25
                        "
            />
            {{
              data.item.appel_facturation.length
                ? data.item.appel_facturation.length
                : '-'
            }}
            <b-popover
              :target="`popover-1-${data.item.id}`"
              triggers="focus"
              custom-class="avoir-tooltip custom-z-index-popover custem-popover-appel-commercial"
            >
              <b-table
                show-empty
                id="my-table"
                class="custom-table-style
              custom-table-piece-commercial table-header"
                :items="data.item.appel_facturation"
                :fields="[
                  { key: 'piece', label: 'Piéce' },
                  { key: 'date', label: 'Date appel à apiement' },
                  { key: 'responsable', label: 'Crée par' }
                ]"
                bordered
                sticky-header
                hover
                responsive
                head-variant="light"
                empty-text="Il
              n'y a aucun enregistrement à afficher"
              >
                <template v-slot:cell(piece)="data">
                  <font-awesome-icon
                    icon="eye"
                    @click="dataToVu = data.item.body"
                    class="icon-style-color mr-1 cursor-pointer"
                    :id="`popover-1-piece-${data.item.id}`"
                  />
                  <font-awesome-icon
                    icon="arrow-alt-circle-down"
                    class="icon-style-color mr-1 cursor-pointer"
                    @click="handleDownloadPiece(data.item)"
                  />
                </template>
                <template v-slot:cell(date)="data">
                  {{ data.item.created_at }}
                </template>
                <template v-slot:cell(responsable)="data">
                  {{ data.item.responsable_payment_call_name }}
                </template>
              </b-table>
            </b-popover>
          </template>
          <template v-slot:cell(statut_appel_commercial)="data">
            <EditableInput
              :editable="checkPermission('GAPTHMCSAP')"
              champName="statut_appel_commercial"
              editableType="selectInput"
              :optionsSelect="computedListStatut"
              :item="data.item"
              :value="data.item.statut_appel_commercial"
              :updateFunction="updateColumnsCommercial"
              label="full_name"
              :positionSelect="true"
              :showIconEdit="true"
            />
          </template>
          <template v-slot:cell(lock)="data">
            <b-button
              size="sm"
              class="button-export-style cdg-color-3"
              title="Annuler l'appel à paiement"
              @click="changeLockAction(data.item)"
              :disabled="data.item.loadingLock"
              v-if="data.item.payment_call == 'oui'"
            >
              <div
                class="spinner-border lettle-spinner"
                role="status"
                v-if="data.item.loadingLock"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="check" v-else
            /></b-button>
            <template v-else>-</template>
          </template>
          <template v-slot:cell(details)="data">
            <button class="btn btn-anomalie" @click="getDataForRow(data)">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les dossiers"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masques les dossiers"
              />
            </button>
          </template>
          <template #row-details="data">
            <template
              v-if="
                data.item.loaderFacture &&
                  data.item &&
                  data.item.factures &&
                  data.item.factures.length === 0
              "
            >
              <circles-to-rhombuses-spinner
                :animation-duration="1500"
                :size="50"
                :color="'#ff1d5e'"
                class="loading-details-frais"
              />
            </template>
            <template v-else>
              <div class="m-3">
                <b-table-simple>
                  <b-thead>
                    <b-tr>
                      <b-th class="th-check-obligee"></b-th>
                      <b-th class="w-100-px">Numéro Facture</b-th>
                      <b-th class="w-78-px">Statut</b-th>
                      <b-th class="">Vendeur</b-th>
                      <b-th class="">Client</b-th>
                      <b-th class="w-78-px">Coefficient</b-th>
                      <b-th class="">HT</b-th>
                      <b-th class="">TTC</b-th>
                      <b-th class="">TVA</b-th>
                      <b-th class="">Object</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(item, index) in data.item.factures"
                      :key="'tr' + index"
                    >
                      <b-td class="col-check-obligee">
                        <b-form-checkbox
                          v-model="item.check"
                          class="check-th-details ml-2"
                          :value="true"
                          :unchecked-value="false"
                        >
                        </b-form-checkbox>
                      </b-td>
                      <b-td class="w-100-px">
                        <b-row>
                          <b-col cols="9">
                            {{ item.num ? item.num : '-' }}</b-col
                          >
                          <b-col cols="2">
                            <custom-iframe
                              :getDataPDF="getDataPDFSimulationCommerciaux"
                              :data="item"
                          /></b-col>
                        </b-row>
                      </b-td>
                      <b-td>{{ item.statue }} </b-td>
                      <b-td>{{ item.vendeur }}</b-td>
                      <b-td>{{ item.client }}</b-td>
                      <b-td class="w-78-px"> {{ item.coef }}</b-td>
                      <b-td>{{ item.ht }} €</b-td>
                      <b-td>{{ item.ttc }} €</b-td>
                      <b-td>{{ item.tva }} €</b-td>
                      <b-td>{{ item.objet }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </template>

            <!-- setDetails -->
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowsAppelPaiement"
            :per-page="per_page"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="per_page"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
        <div v-if="dataToVu != null" class="model-visual-piece-jointe">
          <div
            class="iconCloseModalCommercial"
            @click.prevent="dataToVu = null"
          >
            <font-awesome-icon icon="times" />
          </div>
          <div v-html="dataToVu" class="div-content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import lockAp from './lockApC.vue';
// import RegenererFacturecommerciaux from './component/regenererFacturecommerciaux.vue';

export default {
  name: 'appel-paiement',
  data() {
    return {
      statut_appel_commercial: [],
      dataToVu: null,
      checkAll: false,
      payment_call: null,
      commercial_reste_a_charge: 'tous',
      date_debut: null,
      date_fin: null,
      searchValue: null,
      lots: [],
      installateur: [],
      regie: [],
      agent_commercial: [],
      payment: true,
      orderBy: 'id',
      order: 'DESC',
      page: 1,
      per_page: 20,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      listStatut: [
        {
          id: null,
          full_name: 'Aucun statut'
        },
        {
          id: 'Créé',
          full_name: 'Créé'
        },
        { id: 'A payer', full_name: 'A payer' },
        { id: 'Payé', full_name: 'Payée' }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getSettingFilialeTh',
      'getFactureCommercial',
      'updateColumnsCommercial',
      'paymentCallCommercial',
      'getFilterCommerciaux',
      'updateColumnsCommercialMultiple',
      'fetchListRegieForCommercialpage',
      'changeLockAppelPaiementCommerciauxTh',
      'getFactureAndSimulationForCommercialAppelPayment',
      'getDataPDFSimulationCommerciaux',
      'getAllProFilial',
      'downloadPieceJointe'
    ]),
    handleVisualisePiece(piece) {},
    handleDownloadPiece(piece) {
      this.downloadPieceJointe({ id: piece.id });
    },
    async getDataForRow(data) {
      await data.toggleDetails();
      if (data.detailsShowing == false) {
        data.item.loaderFacture = true;
        await this.getFactureAndSimulationForCommercialAppelPayment(data.item);
        data.item.loaderFacture = false;
      }
    },
    async changeLockAction(item) {
      item.loadingLock = true;
      await this.changeLockAppelPaiementCommerciauxTh([item]);
      item.loadingLock = false;
    },
    rowClass(item, type) {
      if (
        item?.payment_call == 'oui' &&
        item.statut_appel_commercial == 'Payé'
      ) {
        return 'ligneAlert';
      }
      if (item?.payment_call == 'oui') {
        return 'ligneYellow';
      }
      return 'ligneNormale';
    },
    async refrech() {
      this.orderBy = 'id';
      this.order = 'DESC';
      this.handleFilter();
    },
    async listOrder(col, ord) {
      this.orderBy = col;
      this.order = ord;
      this.setLocalStorageAppelPaiementCommercial();
      this.handleFilter();
    },
    reloadData() {
      this.handleFilter();
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    },
    checkAllFunction() {
      this.getAppelPaiementCommerciaux.map(item => {
        item.check = this.checkAll;
      });
    },
    changeSearchValue(event) {
      this.searchValue = event;
      this.handleFilter();
    },
    async handleFilterDate() {
      this.setLocalStorageAppelPaiementCommercial();
      const response = await this.getFilterCommerciaux({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      if (response) {
        this.handleFilter();
      }
    },
    async handleFilter() {
      this.setLocalStorageAppelPaiementCommercial();
      this.getFactureCommercial({
        page: this.page,
        per_page: this.per_page,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        order: this.order,
        orderBy: this.orderBy,
        search: this.searchValue,
        payment: this.payment,
        lots: this.lots,
        installeurs: this.installateur,
        payment_call: this.payment_call,
        commercial_reste_a_charge: this.commercial_reste_a_charge,
        regie: this.regie,
        agent_commercial: this.agent_commercial,
        statut_appel_commercial: this.statut_appel_commercial
      });
    },
    setLocalStorageAppelPaiementCommercial() {
      localStorage.setItem(
        'appel-paiement',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          search: this.searchValue,
          lots: this.lots,
          installeurs: this.installateur,
          order: this.order,
          orderBy: this.orderBy,
          payment_call: this.payment_call,
          commercial_reste_a_charge: this.commercial_reste_a_charge,
          statut_appel_commercial: this.statut_appel_commercial
        })
      );
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    resetModal() {},
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    }
  },
  filters: {
    filterCommercialResteACharge(value) {
      switch (value) {
        case 'tous':
          return 'Tous';
        case 'null':
          return 'égale à 0';
        case 'notnull':
          return 'Supérieur à 0';
        default:
          return value;
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProFilials',
      'checkPermission',
      'getAppelPaiementCommerciaux',
      'getLoadingAppelPaiementCommerciaux',
      'getErrorAppelPaiement',
      'getSettingFilialesTh',
      'getTotalRowsAppelPaiement',
      'getLoadingSettingFilialeTh',
      'filterInstalleurCommercial',
      'filterLotsCommercial',
      'loadingFiltreCommerciaux',
      'getListRegieForCommercialpage',
      'getLoaderListRegieForCommercialpage',
      'isSuperAdminGetter',
      'formateGeneraleDateGetters'
    ]),
    computedListStatut() {
      return this.listStatut.filter(i => i.id != 'true');
    },
    computedCheckDossier() {
      return this.getAppelPaiementCommerciaux.filter(i => i.check == true);
    },
    computedCheckFactures() {
      return this.getAppelPaiementCommerciaux.filter(
        item => item.factures.length && item.factures.some(i => i.check == true)
      );
    },

    computedCheckChipFilter() {
      if (
        this.installateur?.length > 0 ||
        this.lots?.length > 0 ||
        this.regie?.length > 0 ||
        this.agent_commercial?.length > 0 ||
        this.statut_appel_commercial?.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedFilterInstallateur() {
      return this.filterInstalleurCommercial.map(item => {
        return {
          text: item,
          value: item
        };
      });
    },
    computedFilterLots() {
      return this.filterLotsCommercial.map(item => {
        return {
          text: item,
          value: item
        };
      });
    },
    computedFilterPayment() {
      return [
        {
          text: 'Tous',
          value: null
        },
        {
          text: 'Oui',
          value: 'oui'
        },
        {
          text: 'Non',
          value: 'non'
        }
      ];
    },
    computedFilterResteChage() {
      return [
        {
          text: 'Tous',
          value: 'tous'
        },
        {
          text: 'égale à 0',
          value: 'null'
        },
        {
          text: 'Supérieur à  0',
          value: 'notnull'
        }
      ];
    },
    computedGetSettingFilialesTh() {
      return this.getProFilials
        .filter(i => i.type == 'particulier')
        .map(item => {
          return {
            full_name: item.name,
            id: item.id
          };
        });
    },
    computedGetSettingAllFilialesTh() {
      return this.getProFilials.map(item => {
        return {
          full_name: item.name,
          id: item.id
        };
      });
    },
    computedGetSettingAllParticulierTh() {
      return this.getProFilials
        .filter(i => i.type == 'particulier')
        .map(item => {
          return {
            full_name: item.name,
            id: item.id
          };
        });
    },
    computedGetSettingRegiesTh() {
      return this.getListRegieForCommercialpage;
    },
    computedLineFilter() {
      if (
        this.date_debut ||
        this.date_fin ||
        this.searchValue ||
        this.payment_call ||
        this.commercial_reste_a_charge
      ) {
        return true;
      }
      return false;
    },
    // sizeBlockTable() {
    //   let heigthBlock = 0;
    //   heigthBlock = this.computedCheckChipFilter == true ? 40 : 0;
    //   let hei = 174 + heigthBlock;
    //   if (this.computedLineFilter) {
    //     hei = hei + 30;
    //   }
    //   return (
    //     'max-height : calc(100vh - ' +
    //     `${hei}` +
    //     'px)!important ; height: calc(100vh - ' +
    //     `${hei}` +
    //     'px)!important;'
    //   );
    // },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 42
          : 0;
      }
      if (this.computedLineFilter == true) {
        heigthBlock = heigthBlock + 25;
      }
      let hei = 175 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 42
          : 0;
      }
      if (this.computedLineFilter == true) {
        heigthBlock = heigthBlock + 25;
      }
      // calc(100vh - 228px)
      let hei = 233 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckRows() {
      let selected = [];
      this.getAppelPaiementCommerciaux.forEach(project => {
        if (project.check == true && project.payment_call != 'oui') {
          selected.push(project.id);
        }
      });
      return selected;
    },
    computedCheckRowsUpdate() {
      return this.getAppelPaiementCommerciaux.filter(
        item => item.check == true
      );
    },
    computedCheckAllRowsUpdate() {
      return this.getAppelPaiementCommerciaux.filter(
        item => item.check == true && item.payment_call == 'oui'
      ).length == 0
        ? true
        : false;
    },
    computedCheckRowsLock() {
      return this.getAppelPaiementCommerciaux.filter(
        item => item.check == true && item.payment_call == 'oui'
      );
    },
    computedFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true,
          isSortable: true
        },
        {
          key: 'details',
          label: '',
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee',
          show: true
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          show: this.checkPermission('GAPTHACND'),
          isSortable: true
        },
        {
          key: 'installeur',
          label: 'Installateur',
          show: this.checkPermission('GAPTHACIN'),
          isSortable: true
        },
        {
          key: 'nom1',
          label: 'Nom',
          show: this.checkPermission('GAPTHACNOM'),
          isSortable: true
        },
        {
          key: 'prenom1',
          label: 'Prenom',
          show: this.checkPermission('GAPTHACPR'),
          isSortable: true
        },
        {
          key: 'regie',
          label: 'Régie',
          show: this.checkPermission('GAPTHACRG'),
          isSortable: true
        },
        {
          key: 'date_depot_dossier',
          label: 'Date dépôt de dossier',
          show: this.checkPermission('GAPTHACDD'),
          isSortable: true
        },
        {
          key: 'statut',
          label: 'Statut',
          show: this.checkPermission('GAPTHACST'),
          isSortable: true
        },
        {
          key: 'sous_regie',
          label: 'Sous régie',
          show: this.checkPermission('GAPTHACSR'),
          isSortable: true
        },
        {
          key: 'commercial_reste_a_charge',
          label: 'Commercial reste à charge',
          show: this.checkPermission('GAPTHACCRAC'),
          isSortable: true
        },
        {
          key: 'agent_commercial_terrain',
          label: 'Agent commercial terrain',
          show: this.checkPermission('GAPTHACACT'),
          isSortable: true
        },
        {
          key: 'piece_jointe',
          label: 'Pièce jointe',
          show: this.checkPermission('GAPTHFJ'),
          isSortable: false
        },
        {
          key: 'statut_appel_commercial',
          label: 'Statut Appel à paiement',
          show: this.checkPermission('GAPTHSAP'),
          isSortable: false
        },
        {
          key: 'lock',
          label: '',
          thClass: 'colhead-num-dossier-th-simulation  ',
          tdClass: ' col-num-dossier-th-simulation',
          show: this.isSuperAdminGetter
        }
      ];
      return fields.filter(item => item.show == true);
    }
  },

  components: {
    customIframe: () => import('@/views/component/customIframe'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue'),
    // regenererFraisCommerciaux: () =>
    //   import('./component/regenererFraisCommerciaux.vue'),
    filterComponent: () =>
      import('@/views/ThProject/frais/component/filterComponent.vue'),
    updateMultipleColumnAppelPaiement: () =>
      import('./component/updateMultipleColumnAppelPaiement.vue'),
    lockAp,
    // RegenererFacturecommerciaux,
    mailCommercial: () => import('./component/mail/mailCommercial.vue')
  },
  async mounted() {
    this.getAllProFilial();
    await this.getSettingFilialeTh();
    await this.getFilterCommerciaux();
    this.fetchListRegieForCommercialpage();
    if (localStorage.getItem('appel-paiement')) {
      let localStoreValue = JSON.parse(localStorage.getItem('appel-paiement'));

      this.date_debut = localStoreValue.date_debut;
      this.date_fin = localStoreValue.date_fin;
      this.searchValue = localStoreValue.searchValue;
      this.installateur = localStoreValue.installeurs;
      this.lots = JSON.parse(localStorage.getItem('appel-paiement')).lots;
      this.payment_call = localStoreValue.payment_call;
      this.commercial_reste_a_charge = localStoreValue.commercial_reste_a_charge
        ? localStoreValue.commercial_reste_a_charge
        : 'tous';
      this.statut_appel_commercial = localStoreValue.statut_appel_commercial
        ? localStoreValue.statut_appel_commercial
        : [];
      this.regie = localStoreValue.regie;
      this.agent_commercial = localStoreValue.agent_commercial;
    } else {
      this.setLocalStorageAppelPaiementCommercial();
    }

    this.handleFilter();
  }
};
</script>

<style scoped lang="scss">
.body-box-rapport {
  display: flex;
  background-color: #f5f4fa;
  height: calc(100vh - 165px);
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}
.body-box-rapport {
  height: calc(100vh - 68px);
}
.tabs-menu-style {
  width: 37px;
}

.dot-vert-big {
  background-color: rgba(234, 221, 31, 0.64) !important;
}

.dot-red-big {
  background-color: #c3d4c796 !important;
}
.body-box-rapport {
  height: calc(100vh - 68px);
}

.appel-paiement {
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 210px);
    .tableAppelPaiement {
      max-height: calc(100vh - 220px) !important;
      height: calc(100vh - 220px) !important;
      margin-bottom: 0px;
    }
  }
}
.model-visual-piece-jointe {
  position: fixed;
  z-index: 1500;
  width: 100%;
  top: 0px;
  left: 0px;
  background: #726d6dc4;
  height: 100%;
  /* overflow-y: auto; */
  bottom: 0px;
  right: 0px;
  padding: 20px;
  .div-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #8d8cb7;
      border-radius: 7px;
    }
  }
}
</style>
<style lang="scss">
.ligneRed {
  color: red;
}
.underline-selected-colomn {
  text-decoration: underline;
}
.style-arrow-not-selected {
  width: 14px;
  height: 14px;
  color: #aaacb0;
  margin-left: 1px;
  margin-right: 1px;
}
.style-arrow-selected {
  width: 14px;
  height: 14px;
  color: #4d4bac;
  margin-left: 1px;
  margin-right: 1px;
}
.custom-table-piece-commercial {
  max-height: 200px !important;
  height: 200px !important;
}
.table-rapport-style .tableAppelPaiement {
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 171px;
    min-width: 171px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
    background: #dce6de;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 12px;
  }
  th {
    padding: 6px 2px;
    width: 172px;
    min-width: 172px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
}
.fix-width-agent {
  min-width: 135px !important;
}
.lettle-spinner {
  width: 1rem;
  height: 1rem;
}
.custem-popover-appel-commercial {
  min-width: 500px !important;
}
.iconCloseModalCommercial {
  border-radius: 15px;
  width: 22px;
  padding: 3px 5px;
  right: 31px;
  position: fixed;
}
</style>
